import "./styles.scss"

import React from "react"

import Button from "components/Button"

const Apartments = () => {
  return (
    <section className="home-apartments">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <h2>Mieszkania</h2>
            <p>Wybierz mieszkanie dopasowanie do Twoich potrzeb.</p>
            <div className="row">
              <div className="col-4">
                <span>Ilość pokoi</span>
                <strong>1-4</strong>
              </div>
              <div className="col-4">
                <span>Piętro</span>
                <strong>0-4</strong>
              </div>
              <div className="col-12">
                <span>Metraż</span>
                <strong>
                  24m<sup>2</sup> - 63m<sup>2</sup>
                </strong>
              </div>
            </div>
            <Button to="/mieszkania/">Zobacz mieszkania</Button>
          </div>

          <div className="col-md-6">
            <div className="home-apartments__image">
              <img
                src={
                  require("assets/images/home-apartments-apartment.png").default
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Apartments
