import "./styles.scss"

import React from "react"

import Button from "components/Button"

const About = () => {
  return (
    <section className="home-about">
      <div className="container-fluid">
        <div className="row flex-column-reverse flex-lg-row align-items-lg-center">
          <div className="col-lg-7">
            <h2>O inwestycji</h2>
            <p>
              Żyjesz w rytmie miasta? Chcesz być blisko tego co oferuje Kraków,
              Polska, świat, ale jednocześnie mieszkać otoczony spokojem i
              komfortem?
            </p>
            <p>
              <strong>
                Inwestycja City Łobzów dedykowana jest wszystkim tym, którzy
                działają dynamicznie, ale dom traktują jako enklawę spokoju i
                komfortowego wypoczynku
              </strong>
              . Tym ceniącym moc udogodnień i przemyślanych rozwiązań. Weź
              głęboki wdech i ruszaj w miasto, wróć wziąć głęboki wdech. City
              Łobzów.
            </p>
            <div className="home-about__cta">
              <Button to="/o-inwestycji/">Dowiedz się więcej</Button>
            </div>
          </div>

          <div className="col-lg-5">
            <div className="home-about__logo">
              <img
                src={require("assets/images/logo-large-color.svg").default}
                alt="City Łobzów"
              />
            </div>
          </div>
        </div>

        <div className="home-about__image">
          <div
            className="home-about__image-inner"
            style={{
              backgroundImage: `url(${
                require("assets/images/home-about-image.jpg").default
              })`,
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default About
