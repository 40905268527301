import "./styles.scss"

import React from "react"
import { tilesData } from "data"

const Tiles = () => {
  return (
    <section className="home-tiles">
      <div className="container-fluid">
        <div className="row">
          {tilesData?.map((item, index) => (
            <div className="col-md-4" key={index}>
              <div
                className={`home-tiles__item home-tiles__item--${index + 1}`}
                style={{ backgroundImage: `url('${item.image}')` }}
              >
                <div>
                  <p>{item.description}</p>
                </div>
                <img src={item.icon} alt="" />
              </div>
              <h3>{item.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Tiles
