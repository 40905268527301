import "./styles.scss"

import React, { useState } from "react"
import Slider from "react-slick"

import ModalImage from "components/ModalImage"

const GallerySlider = () => {
  const [modalImage, setModalImage] = useState(null)

  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    slidesToShow: 2,
    slidesToScroll: 1,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const data = [
    require("assets/images/gallery/image-6.jpg").default,
    require("assets/images/gallery/image-7.jpg").default,
    require("assets/images/gallery/image-1.jpg").default,
    require("assets/images/gallery/image-2.jpg").default,
    require("assets/images/gallery/image-4.jpg").default,
    require("assets/images/gallery/image-9.jpg").default,
    require("assets/images/gallery/image-8.jpg").default,
    require("assets/images/gallery/image-3.jpg").default,
    require("assets/images/gallery/image-10.jpg").default,
    require("assets/images/gallery/image-5.jpg").default,
    require("assets/images/gallery/image-13.jpg").default,
    require("assets/images/gallery/image-11.jpg").default,
  ]

  return (
    <>
      <section className="home-gallery-slider">
        <div className="container-fluid">
          <h2>Galeria</h2>
          <Slider {...slider}>
            {data.map((item, index) => (
              <div key={index}>
                <button
                  className="home-gallery-slider__item"
                  onClick={() => setModalImage(item)}
                >
                  <span
                    className="home-gallery-slider__image"
                    style={{ backgroundImage: `url('${item}')` }}
                  />
                </button>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <ModalImage image={modalImage} setImage={setModalImage} />
    </>
  )
}

export default GallerySlider
