import "./styles.scss"

import React from "react"
import Slider from "react-slick"

const Gallery = () => {
  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    // autoplay: true,
    // autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  const data = [
    require("assets/images/home-gallery-image-1.jpg").default,
    // require("assets/images/home-gallery-image-2.jpg").default,
  ]

  return (
    <section className="home-gallery">
      <Slider {...slider}>
        {data.map((item, index) => (
          <div key={index}>
            <div
              className="home-gallery__item"
              style={{ backgroundImage: `url('${item}')` }}
            />
          </div>
        ))}
      </Slider>
    </section>
  )
}

export default Gallery
