import React from "react"

import Layout from "components/Layout"

import {
  Hero,
  Tiles,
  About,
  Convenience,
  Apartments,
  Gallery,
  Map,
  GallerySlider,
} from "page_components/home"

const Home = () => (
  <Layout seo={{ title: "Strona główna " }} isHome>
    <Hero />
    <Tiles />
    <About />
    <Convenience />
    <Apartments />
    <Gallery />
    <Map />
    <GallerySlider />
  </Layout>
)

export default Home
