import "./styles.scss"

import React from "react"

import InteractiveMap from "components/InteractiveMap"
import Button from "components/Button"

const Map = () => {
  return (
    <section className="home-map">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-7">
            <h2>Blisko miasta, blisko świata</h2>
          </div>
          <div className="col-lg-5">
            <p>
              Wybierając City Łobzów wybierasz bycie w centrum wydarzeń. Tych
              małych, lokalnych, tych większych w sercu Krakowa, ale i tych
              największych bo z City Łobzów jesteś o krok od całej Polski,
              Europy i Świata. Komunikacja miejska, dworzec kolejowy i
              autobusowy, lotnisko - w ciągu paru chwil możesz ruszyć na miasto,
              w Polskę lub na przygodę życia!
            </p>
            <p>
              <strong>
                City Łobzów przy ul. Głowackiego w Krakowie to inwestycja
                dedykowana wszystkim tym, którzy żyją w rytmie miasta.{" "}
              </strong>
            </p>
            <div className="home-map__cta">
              <Button to="/lokalizacja/">Dowiedz się więcej</Button>
            </div>
          </div>
        </div>

        <div className="home-map__image">
          <InteractiveMap />
        </div>
      </div>
    </section>
  )
}

export default Map
